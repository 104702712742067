@import './variables';
@import './libs/normalize';

@import './components/all';

body {
  background-color: $background;
}

.wrapper {
  width: calc(100vw - 100px);
  height: 100%;
  background-color: $background;
  margin: 50px auto;
  border-radius: 10px;
  max-width: 1400px;

  @media (max-width: 645px) {
    width: calc(100vw - 30px);
    margin-top: 15px;
  }
}

a {
  color: $secondary;
}

.container {
  width: $container-width;
  margin: 0 auto;

  .main_img {
    width: 100%;
  }
}

.content {
  padding: 30px 0;
  width: 90%;
  margin: 0 auto;
}