#team-members {
  padding: 20px;

  p {
    font-weight: bold;
  }

  .member-listing {
    margin-top: 20px;
  }

  .team-qty {
    padding-left: 10px;
    color: $text;
    font-weight: bold;
    font-size: large;
  }

  .table-list-item {
    display: flex;
    align-items: center;
    transition: 1s all;
    padding: 10px;
    border-radius: 10px;

    &:hover {
      background-color: $secondary;
      cursor: pointer;
    }

    div:last-child {
      margin-left: 20px;
    }

    .name {
      font-size: 16px;
      font-weight: 700;
    }

    .alias {
      color: #8b949e;
    }
  }
}
