@import '../variables';

.header {
  border-bottom: 1px solid $secondary;
  padding: 30px 0;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header__right {
      display: flex;
      font-weight: bold;

      a {
        color: $text;
        font-size: 24px;
        margin-left: 20px;
        display: inline-block;
        border-radius: 30px;
        padding: 10px 20px;
        min-width: 100px;
        text-align: center;
        cursor: pointer;
        transition: background-color $duration ease-in-out, border-color $duration ease-in-out;
        border: 1px solid transparent;

        &:hover {
          background-color: $secondary;
        }
      }
    }

    .header__logo {
      display: flex;

      img {
        margin-right: 15px;
      }

      h1 {
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 800;
      }

      p {
        color: #7b7b7b;
      }
    }
  }
}

@media (max-width: 640px) {
  .header {
    padding: 20px 0;

    .container {
      flex-direction: column;

      .header__right {
        margin-top: 20px;

        a:first-child {
          margin-left: 0;
        }
      }

      .header__logo {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        h1 {
          font-size: 20px;
        }
      }
    }
  }
}
