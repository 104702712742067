// light
// TBD

// dark
$background: #060309;
$text: #eadcf5;
$primary: #c495e0;
$secondary: #7e2529;
$accent: #481569;

$container-width: 90%;
$duration: 0.15s;
